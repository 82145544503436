
.info-box {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 500px;
    margin: 20px auto;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.info-box:hover {
    transform: scale(1.05);
}

.info-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.info-description {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.info-button {
    background: #28a745;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.info-button:hover {
    background-color: #218838;
}

@media (max-width: 600px) {
    .info-box {
        padding: 15px;
    }

    .info-title {
        font-size: 20px;
    }

    .info-description {
        font-size: 14px;
    }

    .info-button {
        padding: 8px 16px;
        font-size: 14px;
    }
}
