* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.login-body {
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
}

.login-wave {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    z-index: -1;
}

.login-container {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 7rem;
    padding: 0 2rem;
}

.login-img {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.login-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.login-img img {
    width: 500px;
}

.login-form {
    width: 360px;
    height: auto;
}

.login-content img {
    height: 100px;
}

.login-title {
    margin: 15px 0;
    color: #333;
    text-transform: uppercase;
    font-size: 2.9rem;
}

.login-input-div {
    position: relative;
    display: grid;
    grid-template-columns: 7% 93%;
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 2px solid #d9d9d9;
}

.login-input-div-one {
    margin-top: 0;
}

.login-i {
    color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-i i {
    transition: .3s;
}

.login-input-div > .login-div {
    position: relative;
    height: 45px;
}

.login-input-div > .login-div > .login-h5 {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 18px;
    transition: .3s;
}

.login-input-div::before, .login-input-div::after {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 0%;
    height: 2px;
    background-color: #38d39f;
    transition: .4s;
}

.login-input-div::before {
    right: 50%;
}

.login-input-div::after {
    left: 50%;
}

.login-input-div-focus::before, .login-input-div-focus::after {
    width: 50%;
}

.login-input-div-focus > .login-div > .login-h5 {
    top: -5px;
    font-size: 15px;
}

.login-input-div-focus > .login-i > i {
    color: #38d39f;
}

.login-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    padding: 0.5rem 0.7rem;
    font-size: 1.2rem;
    color: #555;
    font-family: 'Poppins', sans-serif;
}

.login-input-div-pass {
    margin-bottom: 4px;
}

.login-a {
    display: block;
    text-align: right;
    text-decoration: none;
    color: #999;
    font-size: 0.9rem;
    transition: .3s;
}

.login-a:hover {
    color: #38d39f;
}

.login-btn {
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    outline: none;
    border: none;
    background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
    background-size: 200%;
    font-size: 1.2rem;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin: 1rem 0;
    cursor: pointer;
    transition: .5s;
}

.login-btn:hover {
    background-position: right;
}

@media screen and (max-width: 1050px) {
    .login-container {
        grid-gap: 5rem;
    }
}

@media screen and (max-width: 1000px) {
    .login-form {
        width: 290px;
    }

    .login-title {
        font-size: 2.4rem;
        margin: 8px 0;
    }

    .login-img img {
        width: 400px;
    }
}

@media screen and (max-width: 900px) {
    .login-container {
        grid-template-columns: 1fr;
    }

    .login-img {
        display: none;
    }

    .login-wave {
        display: none;
    }

    .login-content {
        justify-content: center;
    }
}

.forgot-password{
    text-align: right;
    margin-top: 1rem;
    font-size: 12px;
    cursor: pointer;
}

.forgot-password:hover{
    color:  #1fb82d;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-left-color: #1fb82d;
    animation: spin 1s ease infinite;
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Center the spinner */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}
