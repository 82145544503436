@import url(https://fonts.googleapis.com/css?family=Exo+2:300,400,700);

.car-types-container {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-family: "Exo 2", sans-serif;
    color: #333;
}

.card {
    position: relative;
    width: 370px;
    height: 550px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.3);
}

.card-header {
    position: relative;
    height: 220px;
    background-size: cover;
    background-position: top;
}

.zylo{
    padding: 20px;
}


.card-body {
    text-align: center;
    padding-left: 10px;
}

.innova{
    padding: 10px;
}


.name {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 auto;
}


.bio {
    font-size: 14px;
    color: #7b7b7b;
    font-weight: 300;
    margin: 10px auto;
    line-height: 20px;
}

@media screen and (max-width: 768px) {

    .car-types-container{
        flex-direction: column;
    }
}


.card-footer {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 20px;
}

.car-type-btn {
    --color: #00A97F;
    padding: 0.8em 1.7em;
    background-color: transparent;
    border-radius: .3em;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .5s;
    font-weight: 400;
    font-size: 17px;
    border: 1px solid;
    font-family: inherit;
    text-transform: uppercase;
    color: var(--color);
    z-index: 1;
}

.car-type-btn::before, .car-type-btn::after {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--color);
    transition: 1s ease;
}

.car-type-btn::before {
    top: -1em;
    left: -1em;
}

.car-type-btn::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

.car-type-btn:hover::before, .car-type-btn:hover::after {
    height: 410px;
    width: 410px;
}

.car-type-btn:hover {
    color: rgb(10, 25, 30);
}

.car-type-btn:active {
    filter: brightness(.8);
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: auto;
}

@media screen and (max-width: 768px) {
    .overlay {
        align-items: flex-start;
    }
}
.overlay-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
}

@media screen and (max-width: 768px) {
    .overlay-content {
        width: 100%;
        max-width: 500px;
    }
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 50px;
    cursor: pointer;
}
