/* PhoneIcon.css */
.phone-icon {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #4CAF50; /* Green */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 10px;
  z-index: 1000; /* Ensure it stays on top */
}

.phone-icon img {
  width: 40px; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
}

.phone-icon:hover {
  background-color: #45a049; /* Darker green on hover */
}